<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Add Form List') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('Add Form Name')" name="name">
      <a-input v-model:value="form.name" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Add Form Value')" name="value">
      <a-input v-model:value="form.value" :size="size" />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import edit from '@/views/reservations/mixins/edit';

export default {
  name: 'EditAddFormList',
  mixins: [edit],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        name: undefined,
        value: undefined,
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const keys = Object.keys(this.form);
        const { details } = nv;
        const addFormList = details.addFormList?.[0] || {};
        keys.forEach((k) => {
          this.form[k] = addFormList[k];
        });
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        await this.editDetails(this.value, [this.form], 'addFormList');
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<i18n src="@/locales/components/reservations.json"></i18n>

<i18n>
{
  "en": {
    "Edit Add Form List": "Edit Add Form List"
  },
  "ja": {
    "Edit Add Form List": "追加フォーム情報を編集"
  }
}
</i18n>
