export default {
  computed: {
    numberOfChildren() {
      if (this.roomInfo) {
        const childACount = this.roomInfo.childA70Count || 0;
        const childBCount = this.roomInfo.childB50Count || 0;

        return parseInt(childACount, 10) + parseInt(childBCount, 10);
      }
      return 0;
    },
    numberOfInfrant() {
      if (this.roomInfo) {
        const childOtherCount = this.roomInfo.childOtherCount || 0;
        const childCCount = this.roomInfo.childC30Count || 0;
        const childDCount = this.roomInfo.childDNoneCount || 0;
        const childECount = this.roomInfo.childECount || 0;
        const childFCount = this.roomInfo.childFCount || 0;

        return (
          parseInt(childOtherCount, 10)
          + parseInt(childCCount, 10)
          + parseInt(childDCount, 10)
          + parseInt(childECount, 10)
          + parseInt(childFCount, 10)
        );
      }
      return 0;
    },
    numberOfAdults() {
      if (this.roomInfo) {
        const femaleCount = this.roomInfo.paxFemaleCount || 0;
        const maleCount = this.roomInfo.paxMaleCount || 0;

        return parseInt(femaleCount, 10) + parseInt(maleCount, 10);
      }
      return 0;
    },
    totalPax() {
      return this.reservedRoom.totalPax || 0;
    },
  },
};
