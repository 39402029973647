<template>
  <div class="columns form is-multiline search-form">
    <div class="column is-12 pb-0">
      <InputProperty
        v-model:value="propertyId"
        :size="size"
        displayed-text="abbreviation"
        style="width: 100%"
        use-room-type
        @change="debounceQuery"
      />
    </div>
    <div class="column is-12">
      <InputRoomType
        v-model:value="roomTypeId"
        :size="size"
        :property-id="propertyId"
        style="width: 100%"
        @change="debounceQuery"
      />
    </div>
    <div
      v-show="roomTypeId || propertyId"
      class="column is-12 pt-0"
    >
      <a-button
        :size="size"
        style="width: 100%"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>

    <!-- chart legend -->
    <div class="column is-12">
      <div class="pl-2">
        <div class="chart-legend booked">
          <i />
          <span>{{ $t('Booked') }}</span>
        </div>
        <div class="chart-legend unavailable">
          <i />
          <span>{{ $t('Blocked / Unavailable') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Clear": "Clear",
    "Booked": "Booked",
    "Blocked / Unavailable": "Blocked/Unavailable"
  },
  "ja": {
    "Clear": "クリア",
    "Booked": "予約済み",
    "Blocked / Unavailable": "ブロック/利用できません"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import InputProperty from '@/components/InputProperty';
import InputRoomType from '@/components/InputRoomType';
import { cleanObject } from '@/utils/util';

export default {
  name: 'SearchForm',
  components: {
    InputRoomType,
    InputProperty,
  },
  props: {
    size: {
      type: String,
      default: 'large',
    },
  },
  emits: ['filter'],
  data() {
    return {
      roomTypeId: undefined,
      propertyId: undefined,
    };
  },
  created() {
    const query = {};

    this.$router
      .push({
        query: {
          ...this.$route.query,
          ...query,
        },
      })
      .then(() => {
        this.roomTypeId = this.$route.query.roomTypeId || undefined;
        this.propertyId = this.$route.query.propertyId || undefined;
        if (this.propertyId) {
          this.$emit('filter');
        }
      });
  },
  methods: {
    debounceQuery: debounce(async function debounceQuery() {
      const query = cleanObject({
        roomTypeId: this.roomTypeId,
        propertyId: this.propertyId,
        date: this.$route.query.date,
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.roomTypeId = undefined;
      this.propertyId = undefined;
      this.debounceQuery();
    },
    setroomTypeId(id) {
      this.roomTypeId = id;
      this.debounceQuery();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-group-title::v-deep {
  background-color: $tab-head-bg;

  span {
    color: $beige-lighter;
    font-weight: $title-weight;
  }

  .ant-checkbox-inner {
    border-color: $rtx-secondary;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $rtx-secondary;
    background-color: $rtx-secondary;
  }
}
.search-form {
  @include tablet {
    width: 220px;
  }
}

.chart-legend {
  display: flex;
  align-items: center;
  margin-top: 10px;
  i {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    margin-right: 8px;
  }
  span {
    display: block;
    line-height: 16px;
  }
  &.booked {
    i {
      background-color: $reservation-calendar-booked-bg;
    }
  }
  &.unavailable {
    i {
      background-color: $reservation-calendar-unavailable-bg;
    }
  }
}
</style>
