<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Room Information') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('Room Type Code')" name="roomCode">
      <a-input v-model:value="form.roomCode" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Type Name')" name="roomName">
      <a-input v-model:value="form.roomName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Pax Male Count')" name="paxMaleCount">
      <a-input-number v-model:value="form.paxMaleCount" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Pax Female Count')" name="paxFemaleCount">
      <a-input-number
        v-model:value="form.paxFemaleCount"
        style="width: 100%"
        min="0"
        :size="size"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child Other Count')" name="childOtherCount">
      <a-input-number
        v-model:value="form.childOtherCount"
        style="width: 100%"
        min="0"
        :size="size"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child Other Name')" name="childOtherName">
      <a-input v-model:value="form.childOtherName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child A 70 Count')" name="childA70Count">
      <a-input-number v-model:value="form.childA70Count" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child A 70 Name')" name="childA70Name">
      <a-input v-model:value="form.childA70Name" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child B 50 Count')" name="childB50Count">
      <a-input-number v-model:value="form.childB50Count" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child B 50 Name')" name="childB50Name">
      <a-input v-model:value="form.childB50Name" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child C 30 Count')" name="childC30Count">
      <a-input-number v-model:value="form.childC30Count" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child C 30 Name')" name="childC30Name">
      <a-input v-model:value="form.childC30Name" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child D None Count')" name="childDNoneCount">
      <a-input-number
        v-model:value="form.childDNoneCount"
        style="width: 100%"
        min="0"
        :size="size"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child D None Name')" name="childDNoneName">
      <a-input v-model:value="form.childDNoneName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child E Count')" name="childECount">
      <a-input-number v-model:value="form.childECount" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child E Name')" name="childEName">
      <a-input v-model:value="form.childEName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child F Count')" name="childFCount">
      <a-input-number v-model:value="form.childFCount" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child F Name')" name="childFName">
      <a-input v-model:value="form.childFName" :size="size" />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import edit from '@/views/reservations/mixins/edit';

export default {
  name: 'EditRoomInfo',
  mixins: [edit],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        roomCode: undefined,
        roomName: undefined,
        name: undefined,
        perRoomPaxCount: undefined,
        paxMaleCount: undefined,
        paxFemaleCount: undefined,
        childOtherCount: undefined,
        childOtherName: undefined,
        childA70Count: undefined,
        childA70Name: undefined,
        childB50Count: undefined,
        childB50Name: undefined,
        childC30Count: undefined,
        childC30Name: undefined,
        childDNoneCount: undefined,
        childDNoneName: undefined,
        childECount: undefined,
        childEName: undefined,
        childFCount: undefined,
        childFName: undefined,
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const keys = Object.keys(this.form);
        const reservedRoom = nv.reservedRoom || {};
        const { info } = reservedRoom;
        const parentKeys = ['roomCode', 'roomName'];

        keys.forEach((k) => {
          if (parentKeys.includes(k)) {
            this.form[k] = reservedRoom[k];
          } else {
            this.form[k] = info[k];
          }
        });
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        const { roomCode, roomName, ...rest } = this.form;
        const payload = {
          reservedRoom: {
            roomCode,
            roomName,
          },
          ...rest,
          perRoomPaxCount: this.totalPaxCount(),
        };
        await this.editReservedRooms(this.value, payload, 'info');
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    totalPaxCount() {
      const { form } = this;
      // child
      const childACount = form.childA70Count || 0;
      const childBCount = form.childB50Count || 0;

      // infant
      const childOtherCount = form.childOtherCount || 0;
      const childCCount = form.childC30Count || 0;
      const childDCount = form.childDNoneCount || 0;
      const childECount = form.childECount || 0;
      const childFCount = form.childFCount || 0;

      // adult
      const femaleCount = form.paxFemaleCount || 0;
      const maleCount = form.paxMaleCount || 0;

      const totalChild = childACount + childBCount + childOtherCount
      + childCCount + childDCount + childECount + childFCount;

      const totalAdult = femaleCount + maleCount;

      return (totalChild + totalAdult);
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<i18n src="@/locales/components/reservations.json"></i18n>

<i18n>
{
  "en": {
    "Edit Room Information": "Edit Room Information"
  },
  "ja": {
    "Edit Room Information": "部屋情報を編集"
  }
}
</i18n>
