<template>
  <div
    :class="cellClass"
    class="calendar-cell"
    @click="onClicked"
  >
    {{ booked }}/{{ sellable }}
  </div>
</template>

<script>
import roles from '@/components/mixins/roles';

export default {
  name: 'CalendarCell',
  mixins: [roles],
  props: {
    dateObj: {
      type: Object, // moment obj
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      default: 0,
    },
    lastPosition: {
      type: Number,
      default: 0,
    },
  },
  emits: ['change'],
  data() {
    return {};
  },
  computed: {
    booked() {
      return this.value?.booked || 0;
    },
    sellable() {
      return this.value?.sellable || 0;
    },
    total() {
      return this.value?.total || 0;
    },
    blocked() {
      return this.value?.blocked || 0;
    },
    cellClass() {
      if (this.sellable === 0 && this.blocked > 0 && this.blocked === this.total) {
        return 'unavailable';
      }

      if (this.booked > 0 && this.sellable > 0 && this.booked === this.sellable) {
        return 'booked';
      }

      return '';
    },
  },
  methods: {
    onClicked() {
      this.goToReservations();
    },
    goToReservations() {
      const { propertyId } = this.$route.query;
      const sortField = 'checkin';
      const sortOrder = 'ASC';
      const checkin = `${this.date},${this.date}`;
      const routeData = this.$router.resolve({
        name: 'reservations',
        query: {
          propertyId,
          roomTypeId: this.value?.id,
          checkin,
          sortField,
          sortOrder,
        },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-cell {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-weight: bold;
  color: $reservation-calendar-default-text;
  background-color: $reservation-calendar-default-bg;
  &.booked {
    color: $reservation-calendar-booked-text;
    background-color: $reservation-calendar-booked-bg;
  }
  &.unavailable {
    color: $reservation-calendar-unavailable-text;
    background-color: $reservation-calendar-unavailable-bg;
  }
}
</style>
