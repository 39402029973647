export default {
  data() {
    return {
      times: {
        priorTo: this.$t('prior'),
        after: this.$t('after'),
      },
    };
  },
  methods: {
    guestEmailStatusClass(emailLog) {
      if (!emailLog) {
        return '';
      }

      const { status } = emailLog;

      return `email-status--${status}`;
    },
    getSendTime(record = {}) {
      const { emailTemplate } = record || {};

      if (!emailTemplate) {
        return '-';
      }

      const { timeValue, time, deletedAt } = emailTemplate;
      const deleted = deletedAt ? ' (deleted)' : '';

      if (emailTemplate.timeValue > 0) {
        return `${timeValue}d ${this.times[time]}${deleted}`;
      }

      return `${timeValue}${deleted}`;
    },
  },
};
