<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Member') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('User Name')" name="name">
      <a-input v-model:value="form.name" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('User Kana')" name="kana">
      <a-input v-model:value="form.kana" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Print Category')" name="printCategory">
      <a-input v-model:value="form.printCategory" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Member Status')" name="status">
      <a-input v-model:value="form.status" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('User Tel')" name="phone">
      <a-input v-model:value="form.phone" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('User Givin Points')" name="givinPoints">
      <a-input v-model:value="form.givinPoints" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('User Mail Address')" name="email">
      <a-input v-model:value="form.email" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('User Zip')" name="zip">
      <a-input v-model:value="form.zip" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('User Addr')" name="phone">
      <a-input v-model:value="form.address" :size="size" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Total Accommodation Declease Points')"
      name="totalAccommodationDecleasePoints"
    >
      <a-input v-model:value="form.totalAccommodationDecleasePoints" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('User Corp')" name="corporation">
      <a-input v-model:value="form.corporation" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Amount Claimed')" name="amountClaimed">
      <a-input-number v-model:value="form.amountClaimed" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Member Number')" name="number">
      <a-input v-model:value="form.number" :size="size" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Representative Person Age')"
      name="representativePersonAge"
    >
      <a-input-number
        v-model:value="form.representativePersonAge"
        style="width: 100%"
        min="0"
        :size="size"
      />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import edit from '@/views/reservations/mixins/edit';

export default {
  name: 'EditMember',
  mixins: [edit],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        name: undefined,
        email: undefined,
        kana: undefined,
        phone: undefined,
        address: undefined,
        zip: undefined,
        corporation: undefined,
        number: undefined,
        printCategory: undefined,
        status: undefined,
        givinPoints: undefined,
        totalAccommodationDecleasePoints: undefined,
        amountClaimed: undefined,
        representativePersonAge: undefined,
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const keys = Object.keys(this.form);
        const { details } = nv;
        const { representativePersonAge } = details;
        const member = details.member || {};

        keys.forEach((k) => {
          if (k === 'representativePersonAge') {
            this.form[k] = representativePersonAge;
          } else {
            this.form[k] = member[k];
          }
        });
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        const { representativePersonAge, ...rest } = this.form;
        const payload = {
          details: { representativePersonAge },
          ...rest,
        };
        await this.editDetails(this.value, payload, 'member');
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<i18n src="@/locales/components/reservations.json"></i18n>

<i18n>
{
  "en": {
    "Edit Member": "Edit Member"
  },
  "ja": {
    "Edit Member": "予約者・会員情報を編集"
  }
}
</i18n>
