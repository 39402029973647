export default {
  methods: {
    async editDetails(value, data, field = null) {
      const details = data.details || {};
      const checkinCheckout = data.checkinCheckout || {};

      if (details) {
        delete data.details;
      }

      if (checkinCheckout) {
        delete data.checkinCheckout;
      }

      const payload = {
        id: this.$route.params.id,
        propertyId: value.propertyId,
        ...checkinCheckout,
        details: {
          ...value.details,
          ...details,
        },
      };

      if (field) {
        payload.details[field] = {
          ...value.details[field],
          ...data,
        };
      }

      await this.$store.dispatch('reservations/update', payload);
    },
    async editReservedRooms(value, data, field = null) {
      const reservedRoom = data.reservedRoom || {};

      if (reservedRoom) {
        delete data.reservedRoom;
      }

      const payload = {
        id: this.$route.params.id,
        propertyId: value.propertyId,
        reservedRoom: {
          ...value.reservedRoom,
          ...reservedRoom,
        },
      };

      if (field) {
        payload.reservedRoom[field] = this.mergeReservedRoomField(value, data, field);
      }

      await this.$store.dispatch('reservations/update', payload);
    },
    mergeReservedRoomField(value, data, field) {
      if (field === 'guests') {
        const currentData = value.reservedRoom[field][0];

        if (!currentData) {
          return [data];
        }

        value.reservedRoom[field][0] = { ...currentData, ...data };

        return value.reservedRoom[field];
      }

      return {
        ...value.reservedRoom[field],
        ...data,
      };
    },
  },
};
