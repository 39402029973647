<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('Edit Room Rate Information') }}
      </h3>
    </div>
    <a-form-item class="column is-12" :label="$t('Room Date')" name="roomDate">
      <a-date-picker
        v-model:value="form.roomDate"
        :value-format="dateFormat"
        :size="size"
        style="width: 100%"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Per Pax Rate')" name="perPaxRate">
      <a-input-number v-model:value="form.perPaxRate" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child A70 Rate Name')" name="childA70Rate">
      <a-input v-model:value="form.childA70RateName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child A70 Rate')" name="childA70Rate">
      <a-input-number v-model:value="form.childA70Rate" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('Room Child B50 Rate Name')"
      name="childB50RateName"
    >
      <a-input v-model:value="form.childB50RateName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child B50 Rate')" name="childB50RateName">
      <a-input-number v-model:value="form.childB50Rate" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child C Rate Name')" name="childCRateName">
      <a-input v-model:value="form.childCRateName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child C Rate')" name="childCRate">
      <a-input-number v-model:value="form.childCRate" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child D Rate Name')" name="childDRateName">
      <a-input v-model:value="form.childDRateName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child D Rate')" name="childDRate">
      <a-input-number v-model:value="form.childDRate" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child E Rate Name')" name="childERateName">
      <a-input v-model:value="form.childERateName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child E Rate')" name="childERate">
      <a-input-number v-model:value="form.childERate" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child F Rate Name')" name="childFRateName">
      <a-input v-model:value="form.childFRateName" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Child F Rate')" name="childFRate">
      <a-input-number v-model:value="form.childFRate" style="width: 100%" min="0" :size="size" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Total Per Room Rate')" name="totalPerRoomRate">
      <a-input-number
        v-model:value="form.totalPerRoomRate"
        style="width: 100%"
        min="0"
        :size="size"
      />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
import edit from '@/views/reservations/mixins/edit';

export default {
  name: 'EditRoomRateInfo',
  mixins: [edit],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      form: {
        roomDate: undefined,
        perPaxRate: undefined,
        childA70RateName: undefined,
        childA70Rate: undefined,
        childB50RateName: undefined,
        childB50Rate: undefined,
        childDRateName: undefined,
        childDRate: undefined,
        childERateName: undefined,
        childERate: undefined,
        childFRateName: undefined,
        childFRate: undefined,
        totalPerRoomRate: undefined,
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        const keys = Object.keys(this.form);
        const { reservedRoom } = nv;
        const rate = reservedRoom.rates[0] || {};
        keys.forEach((k) => {
          this.form[k] = rate[k];
        });
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.loading = true;
        await this.editReservedRooms(this.value, [this.form], 'rates');
        this.$refs.form.resetFields();
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<i18n src="@/locales/components/reservations.json"></i18n>

<i18n>
{
  "en": {
    "Edit Room Rate Information": "Edit Room Rate Information"
  },
  "ja": {
    "Edit Room Rate Information": "部屋料金情報を編集"
  }
}
</i18n>
